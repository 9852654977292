<template>
  <div class="h-100" ref="settingTab" v-if="!loadingState">
    <div class="row gx-20">
      <div class="col-6">
        <div class="d-flex flex-column h-100">
          <div class="system-block mb-10 py-10 flex-grow-1">
            <p class="fw-semi-bold fsc-14 mb-15 text-dark text-center">
              {{ dataLang['vehicle_modal_settings_text1'] }}
            </p>
            <div class="mb-10">
              <div class="d-flex justify-content-between mb-10">
                <div class="">
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__local_nodriver"
                    v-model="dataForModal.vehicles__local_nodriver"
                    signClass="fsc-12 text-dark"
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    name="vehicles__local_nodriver"
                    >{{
                      dataLang['vehicle_modal_settings_text2']
                    }}</BaseCheckboxV2
                  >
                </div>
                <div ref="vehicles__local_nodriver_min_days" class="">
                  <BaseInputNew
                    placeholder=""
                    width="100"
                    input-name="vehicles__local_nodriver_min_days"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicles__local_nodriver_min_days"
                    :additionalParams="{}"
                    :name-sign="dataLang['vehicle_modal_settings_text4']"
                  >
                  </BaseInputNew>
                </div>
                <div ref="local_is_subscription" class="">
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="local_is_subscription"
                    v-model="dataForModal.local_is_subscription"
                    signClass="fsc-12"
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    name="local_is_subscription"
                    >{{
                      dataLang['vehicle_modal_settings_text40']
                    }}</BaseCheckboxV2
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between mb-10">
                <div class="">
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__local_driver"
                    v-model="dataForModal.vehicles__local_driver"
                    signClass="fsc-12 text-dark"
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    name="isActive"
                    >{{
                      dataLang['vehicle_modal_settings_text5']
                    }}</BaseCheckboxV2
                  >
                </div>
                <div ref="vehicles__local_driver_min_hours" class="">
                  <BaseInputNew
                    placeholder=""
                    width="100"
                    input-name="vehicles__local_driver_min_hours"
                    @input="updateValue"
                    signClass="ws-nowrap"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicles__local_driver_min_hours"
                    :additionalParams="{}"
                    :name-sign="dataLang['vehicle_modal_settings_text6']"
                  >
                  </BaseInputNew>
                </div>
                <div ref="vehicles__local_driver_add_hour" class="">
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__local_driver_add_hour"
                    v-model="dataForModal.vehicles__local_driver_add_hour"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text8']
                    }}</BaseCheckboxV2
                  >
                </div>
                <div ref="vehicles__local_driver_km_in_hour" class="">
                  <BaseInputNew
                    :width="'30'"
                    input-name="vehicles__local_driver_km_in_hour"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicles__local_driver_km_in_hour"
                    :additionalParams="{}"
                    :name-sign="dataLang['vehicle_modal_settings_text7']"
                  >
                  </BaseInputNew>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="">
                  <BaseCheckboxV2
                    placeholder=""
                    class="mb-10"
                    input-name="vehicles__local_buyout"
                    v-model="dataForModal.vehicles__local_buyout"
                    signClass="fsc-12 text-dark"
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    name="isActive"
                    >{{
                      dataLang['vehicle_modal_settings_text9']
                    }}</BaseCheckboxV2
                  >
                </div>

                <div ref="vehicles__local_buyout_12" class="mb-10">
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__local_buyout_12"
                    v-model="dataForModal.vehicles__local_buyout_12"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text10']
                    }}</BaseCheckboxV2
                  >
                </div>
                <div ref="vehicles__local_buyout_24" class="mb-10">
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__local_buyout_24"
                    v-model="dataForModal.vehicles__local_buyout_24"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text11']
                    }}</BaseCheckboxV2
                  >
                </div>
                <div ref="vehicles__local_buyout_36" class="">
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__local_buyout_36"
                    v-model="dataForModal.vehicles__local_buyout_36"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text12']
                    }}</BaseCheckboxV2
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="bg-system-secondary text-center py-15 fsc-12 fw-medium text-dark rounded-5"
          >
            {{ dataLang['vehicle_modal_settings_text32'] }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex flex-column">
          <div class="system-block border border-3 border-primary mb-10 p-10">
            <p class="fw-semi-bold fsc-14 mb-15 text-dark text-center">
              {{ dataLang['vehicle_modal_settings_text13'] }}
            </p>
            <div class="">
              <div class="d-flex justify-content-between mb-10">
                <div ref="vehicles__ygsf_city_no_driver" class="">
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__ygsf_city_no_driver"
                    v-model="dataForModal.vehicles__ygsf_city_no_driver"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text14']
                    }}</BaseCheckboxV2
                  >
                  <!-- <span class="fsc-11">{{
                    dataLang['vehicle_modal_settings_text17']
                  }}</span> -->
                </div>
                <div ref="vehicles__ygsf_city_no_driver_min_days" class="">
                  <BaseInputNew
                    placeholder=""
                    :width="'100'"
                    input-name="vehicles__ygsf_city_no_driver_min_days"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicles__ygsf_city_no_driver_min_days"
                    :additionalParams="{}"
                    :name-sign="dataLang['vehicle_modal_settings_text4']"
                  >
                  </BaseInputNew>
                  <!-- <span class="fsc-11 text-muted">{{
                    dataLang['vehicle_modal_settings_text19']
                  }}</span> -->
                </div>
                <div ref="ygsf_city_is_subscription" class="">
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="ygsf_city_is_subscription"
                    v-model="dataForModal.ygsf_city_is_subscription"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text40']
                    }}</BaseCheckboxV2
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between mb-10">
                <div ref="vehicles__ygsf_city_with_driver" class="">
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__ygsf_city_with_driver"
                    v-model="dataForModal.vehicles__ygsf_city_with_driver"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text15']
                    }}</BaseCheckboxV2
                  >
                  <!-- <span class="fsc-11">{{
                    dataLang['vehicle_modal_settings_text17']
                  }}</span> -->
                </div>
                <div ref="vehicles__ygsf_city_with_driver_min_hour" class="">
                  <BaseInputNew
                    placeholder=""
                    :width="'100'"
                    input-name="vehicles__ygsf_city_with_driver_min_hour"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="
                      dataForModal.vehicles__ygsf_city_with_driver_min_hour
                    "
                    :additionalParams="{}"
                    :name-sign="dataLang['vehicle_modal_settings_text6']"
                  >
                  </BaseInputNew>

                  <!-- <span class="fsc-11 text-muted">{{
                    dataLang['vehicle_modal_settings_text20']
                  }}</span> -->
                </div>
                <!-- <div ref="vehicles__local_driver_min_hours" class="">
                  <BaseInputNew
                    placeholder=""
                    width="100"
                    input-name="vehicles__local_driver_min_hours"
                    @input="updateValue"
                    signClass="ws-nowrap"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicles__local_driver_min_hours"
                    :additionalParams="{}"
                    :name-sign="dataLang['vehicle_modal_settings_text6']"
                  >
                  </BaseInputNew>
                </div> -->
                <div ref="ygsf_city_add_hour" class="">
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__local_driver_add_hour"
                    v-model="dataForModal.vehicles__local_driver_add_hour"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text8']
                    }}</BaseCheckboxV2
                  >
                </div>
                <div ref="ygfs_city_km_in_hour" class="">
                  <BaseInputNew
                    :width="'30'"
                    input-name="vehicles__local_driver_km_in_hour"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicles__local_driver_km_in_hour"
                    :additionalParams="{}"
                    :name-sign="dataLang['vehicle_modal_settings_text7']"
                  >
                  </BaseInputNew>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div
                  ref="vehicles__ygsf_city_buyout"
                  class="d-flex align-items-center gap-5"
                >
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__ygsf_city_buyout"
                    v-model="dataForModal.vehicles__ygsf_city_buyout"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text16']
                    }}</BaseCheckboxV2
                  >
                  <!-- <span class="fsc-11">{{
                    dataLang['vehicle_modal_settings_text17']
                  }}</span> -->
                </div>

                <div
                  ref="vehicles__ygsf_city_buyout_12"
                  class="d-flex align-items-center gap-5"
                >
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__ygsf_city_buyout_12"
                    v-model="dataForModal.vehicles__ygsf_city_buyout_12"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text21']
                    }}</BaseCheckboxV2
                  >
                </div>
                <div
                  ref="vehicles__ygsf_city_buyout_24"
                  class="d-flex align-items-center gap-5"
                >
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__ygsf_city_buyout_24"
                    v-model="dataForModal.vehicles__ygsf_city_buyout_24"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text22']
                    }}</BaseCheckboxV2
                  >
                </div>
                <div
                  ref="vehicles__ygsf_city_buyout_36"
                  class="d-flex align-items-center gap-5"
                >
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__ygsf_city_buyout_36"
                    v-model="dataForModal.vehicles__ygsf_city_buyout_36"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text23']
                    }}</BaseCheckboxV2
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="system-block border border-3 flex-grow-1 border-primary p-10"
          >
            <p class="fw-semi-bold fsc-14 mb-15 text-dark text-center">
              {{ dataLang['vehicle_modal_settings_text24'] }}
            </p>
            <div
              class="d-flex gap-15 border border-1 mb-5 rounded-5 py-10 px-15 flex-column"
            >
              <div class="d-flex justify-content-between wp-100">
                <div
                  ref="vehicles__ygs_world"
                  class="d-flex align-items-center gap-5"
                >
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__ygs_world"
                    v-model="dataForModal.vehicles__ygs_world"
                    signClass="fsc-14 fw-semi-bold text-dark"
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text25']
                    }}</BaseCheckboxV2
                  >
                </div>
                <div
                  ref="vehicles__ygs_world_min_days"
                  class="d-flex align-items-center gap-5"
                >
                  <BaseInputNew
                    placeholder=""
                    :width="'30'"
                    input-name="vehicles__ygs_world_min_days"
                    @input="updateValue"
                    :horizontalTitle="true"
                    signClass="text-dark"
                    :value="dataForModal.vehicles__ygs_world_min_days"
                    :additionalParams="{}"
                    :name-sign="dataLang['vehicle_modal_settings_text18']"
                  >
                  </BaseInputNew>
                  <span class="fsc-11 text-dark">{{
                    dataLang['vehicle_modal_settings_text19']
                  }}</span>
                </div>
                <div
                  ref="vehicles__ygs_world_buyout"
                  class="d-flex align-items-center gap-5"
                >
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="ygs_world_is_subscription"
                    v-model="dataForModal.ygs_world_is_subscription"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text40']
                    }}</BaseCheckboxV2
                  >
                </div>
                <div
                  ref="vehicles__ygs_world_buyout"
                  class="d-flex align-items-center gap-5"
                >
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__ygs_world_buyout"
                    v-model="dataForModal.vehicles__ygs_world_buyout"
                    signClass="fsc-12 "
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text26']
                    }}</BaseCheckboxV2
                  >
                </div>
              </div>
              <p
                class="mb-0 fsc-11 text-muted"
                v-html="dataLang['vehicle_modal_settings_text27']"
              ></p>
            </div>
            <div
              class="d-flex gap-15 border border-1 mb-5 rounded-5 py-10 px-15 flex-column"
            >
              <div class="d-flex justify-content-between wp-100">
                <div
                  ref="vehicles__ygf_world"
                  class="d-flex align-items-center gap-5"
                >
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__ygf_world"
                    v-model="dataForModal.vehicles__ygf_world"
                    signClass="fsc-14 fw-semi-bold text-dark"
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text28']
                    }}</BaseCheckboxV2
                  >
                </div>
                <div
                  ref="vehicles__ygf_world_min_days"
                  class="d-flex align-items-center gap-5"
                >
                  <BaseInputNew
                    placeholder=""
                    :width="'30'"
                    input-name="vehicles__ygf_world_min_days"
                    @input="updateValue"
                    :horizontalTitle="true"
                    signClass="text-dark"
                    :value="dataForModal.vehicles__ygf_world_min_days"
                    :additionalParams="{}"
                    :name-sign="dataLang['vehicle_modal_settings_text18']"
                  >
                  </BaseInputNew>
                  <span class="fsc-11 text-dark">{{
                    dataLang['vehicle_modal_settings_text19']
                  }}</span>
                </div>
              </div>
              <p class="mb-0 fsc-11 text-muted">
                {{ dataLang['vehicle_modal_settings_text29'] }}
              </p>
            </div>
            <div
              class="d-flex gap-15 border border-1 rounded-5 py-10 px-15 flex-column"
            >
              <div class="d-flex wp-100">
                <div
                  ref="vehicles__ygt_world"
                  class="d-flex align-items-center gap-5"
                >
                  <BaseCheckboxV2
                    placeholder=""
                    input-name="vehicles__ygt_world"
                    v-model="dataForModal.vehicles__ygt_world"
                    signClass="fsc-14 fw-semi-bold text-dark"
                    @oncheckeds="updateValue"
                    :additionalParams="{}"
                    >{{
                      dataLang['vehicle_modal_settings_text30']
                    }}</BaseCheckboxV2
                  >
                </div>
              </div>
              <p
                class="mb-0 fsc-11 text-muted"
                v-html="dataLang['vehicle_modal_settings_text31']"
              ></p>
            </div>
          </div>
          <!-- <div
            class=" fsc-14 fw-medium text-dark rounded-5"
            style="height: 55px"
            :class="calcClassForExeption"
          >
            <div
              v-if="
                !dataForModal.vehicles__moderation_status_id ||
                dataForModal.vehicles__moderation_status_id == 1
              "
              @click="changeStatusVehicle"
              class="cursor-pointer text-center d-flex justify-content-center align-items-center h-100"
            >
              <span v-html="dataLang['vehicle_modal_settings_text34']"></span>
            </div>

            <div class="cursor-pointer text-center d-flex justify-content-center align-items-center h-100"
              v-if="statusVehicle.vehicles__moderation_status_id == 2"
              v-html="dataLang['vehicle_modal_settings_text36']"
            ></div>
            <div class="cursor-pointer text-center d-flex justify-content-center align-items-center h-100 fsc-11 px-10"
              v-if="statusVehicle.vehicles__moderation_status_id == 3"
              v-html="dataLang['vehicle_modal_settings_text37']"
            ></div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import ObjectsService from '@/api/ObjectsService'

import EventBus from '@/event-bus.js'
import AddNewTriesModal from '@/components/vehicles-page-single-modal-inner/AddNewTriesModal.vue'
import TransportVehiclesService from '@/api/TransportVehiclesService.js'
import httpInstance from '@/http-common.js'
//   import ObjectsService from '@/api/ObjectsService'
//   import EventBus from '@/event-bus.js'
//   import SingleModal from '@/components/SingleModal.vue'
export default {
  components: {
    AddNewTriesModal
  },
  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    },

    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      statusVehicle: '',
      loadingState: true,
      dataForModal: {},
      updatedValues: {}
    }
  },
  methods: {
    changeStatusVehicle() {
      console.log(12121)
      this.$emit('loadingModal', true)
      TransportVehiclesService.update(this.currentOpenedId, {
        vehicles__moderation_status_id: 2
      })
        .then((data) => {
          this.$toast.success(data.data.message)
          this.dataForModal.vehicles__moderation_status_id = 2
          EventBus.$emit('contentLoaded')
          EventBus.$emit('updateContentData')
          this.$emit('loadingModal', false)
        })
        .catch((err) => {
          this.$toast.err(err.response.data.message)
        })
    },
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      if (Object.keys(this.updatedValues).length) {
        ObjectsService.update(this.currentOpenedId, {
          ...this.updatedValues
        })
          .then((data) => {
            console.log(data)
            this.$toast.success(data.data.message)
            callback(true, nameTab, oldTab, newTab, flagIsClose)

            EventBus.$emit('contentLoaded')
            EventBus.$emit('updateContentData')
          })
          .catch((error) => {
            callback(false, nameTab, oldTab, newTab)
            this.$toast.error(error.response.data.message)
          })
      } else {
        callback(true, nameTab, oldTab, newTab)
      }
    },
    updateValue(data) {
      console.log(data)
      this.updatedValues[data.name] = data.value
      console.log(this.updatedValues)
      //   this.findAndReplaceDataForSend(data, data.name, data.value)

      //   if (data.once == 'first') {
      //     this.triesListForSend[1].isActive = 1
      //     this.triesListForSend[0].isActive = 0
      //   } else {
      //     this.triesListForSend[0].isActive = 1
      //     this.triesListForSend[1].isActive = 0
      //   }

      //   if (this.$refs[data.name]) {
      //     this.$refs[data.name].classList.remove('input-error')
      //   }
    },

    ...mapActions('transportVehiclesModule', ['getHealth', 'getTitle'])
  },
  mounted() {},
  created() {
    this.$emit('loadingModal', true)
    this.getHealth(this.currentOpenedId)
    this.getTitle(this.currentOpenedId)

    Promise.all([
      ObjectsService.getAll({
        url: `/${this.currentOpenedId}?fields=vehicles__ygsf_city_no_driver,
        vehicles__moderation_status_id,
vehicles__ygsf_city_no_driver_min_days,
vehicles__ygsf_city_with_driver,
vehicles__ygsf_city_with_driver_min_hour,
vehicles__ygsf_city_buyout,
vehicles__ygsf_city_buyout_12,
vehicles__ygsf_city_buyout_24,
vehicles__ygsf_city_buyout_36,
vehicles__ygs_world,
vehicles__ygs_world_buyout,
vehicles__ygs_world_min_days,
vehicles__ygf_world,
vehicles__ygf_world_min_days,
vehicles__ygt_world,
vehicles__local_buyout_12,
vehicles__local_buyout_24,
vehicles__local_buyout_36,
vehicles__local_driver,
vehicles__local_driver_add_hour,
vehicles__local_nodriver_pledge,
vehicles__local_nodriver_min_days,
vehicles__local_driver_km_in_hour,
vehicles__local_driver_min_hours,
vehicles__local_nodriver, vehicles__local_buyout`
      })
        .then((data) => {
          console.log(data)
          this.dataForModal = data.data.data.rows[0]
          this.statusVehicle = this.dataForModal
        })
        .catch((err) => {
          console.log(err)
        })
    ]).then(() => {
      console.log('This end')
      this.$emit('loadingModal', false)
      this.loadingState = false
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('transportVehiclesModule', ['numPages'])
  }
}
</script>

<style scoped>
.bg-greensetting {
  background-color: rgba(35, 177, 77, 1);
}
</style>
