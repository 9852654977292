<template>
  <div class="container-fluid px-0" ref="vehicleTab" v-if="!loadingState">
    <div class="row gx-10">
      <div class="col-12">
        <div class="d-flex align-items-center gap-15 mb-5">
          <div class="system-block">
            <p class="fw-bolder fsc-12 mb-15">
              {{ dataLang['vehicle_modal_security_osago_title'] }}
            </p>
            <div class="">
              <div class="d-flex mb-10 gap-10">
                <div ref="vehicle_osago__policy">
                  <BaseInputNew
                    placeholder=""
                    :width="'245'"
                    input-name="vehicle_osago__policy"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicle_osago__policy"
                    :name-sign="dataLang['vehicle_modal_security_osago_policy']"
                  >
                  </BaseInputNew>
                </div>
                <div
                  class="d-flex gap-5 justify-content-between align-items-center"
                >
                  <span class="text-muted fsc-12">
                    {{ dataLang['vehicle_modal_security_osago_policy_from'] }}
                  </span>
                  <div>
                    <BaseDatePicker
                      v-model="dataTimeSelect.vehicle_osago__policy_from"
                      type="date"
                      class="fsc-11 idiots-class-for-input-date"
                      lang="ru"
                      id="vehicle_osago__policy_from"
                      style="width: 110px"
                      value-type="format"
                      :format="userSetting.company__tempalte_date"
                      @change="
                        (date) =>
                          changeDate(
                            date,
                            'vehicle_osago__policy_from',
                            {},
                            true
                          )
                      "
                    ></BaseDatePicker>
                  </div>
                </div>
                <div
                  class="d-flex gap-5 justify-content-between align-items-center"
                  ref="vehicle_osago__policy_to"
                >
                  <span class="text-muted fsc-12">
                    {{ dataLang['vehicle_modal_security_osago_policy_to'] }}
                  </span>
                  <div>
                    <BaseDatePicker
                      v-model="dataTimeSelect.vehicle_osago__policy_to"
                      type="date"
                      class="fsc-11 idiots-class-for-input-date"
                      lang="ru"
                      style="width: 110px"
                      value-type="format"
                      :format="userSetting.company__tempalte_date"
                      @change="
                        (date) =>
                          changeDate(date, 'vehicle_osago__policy_to', {}, true)
                      "
                    ></BaseDatePicker>
                  </div>
                </div>
                <div ref="vehicle_osago__cost">
                  <BaseInputNew
                    placeholder=""
                    :width="'265'"
                    input-name="vehicle_osago__cost"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicle_osago__cost"
                    :name-sign="
                      dataLang['vehicle_modal_security_osago_policy_cost']
                    "
                  >
                  </BaseInputNew>
                </div>
              </div>
              <div class="d-flex mb-10 gap-10">
                <div ref="vehicle_osago__company_name" class="wp-100">
                  <BaseInputNew
                    placeholder=""
                    :width="'717'"
                    input-name="vehicle_osago__company_name"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicle_osago__company_name"
                    :name-sign="
                      dataLang[
                        'vehicle_modal_security_osago_policy_company_name'
                      ]
                    "
                  >
                  </BaseInputNew>
                </div>
                <div ref="vehicle_osago__company_phone">
                  <BaseInputNew
                    placeholder=""
                    :width="'140'"
                    input-name="vehicle_osago__company_phone"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicle_osago__company_phone"
                    inputRef="vehicle_osago__company_phone"
                    inputMask="+{7}(000)000-00-00"
                    :name-sign="
                      dataLang[
                        'vehicle_modal_security_osago_policy_company_phone'
                      ]
                    "
                    maxLength="16"
                  >
                  </BaseInputNew>
                </div>
              </div>
              <div class="">
                <span class="text-muted fsc-12">
                  {{ dataLang['vehicle_modal_security_osago_file'] }}
                </span>
                <div class="d-flex align-items-center gap-10 mt-10">
                  <span class="fsc-12" v-if="remoteAndAddedFile">
                    Загружен
                  </span>
                  <a
                    class="fsc-12 text-decoration-underline"
                    :href="remoteAndAddedFile"
                    target="_blank"
                    v-if="remoteAndAddedFile"
                  >
                    {{ remoteAndAddedFile.split('/').at(-1) }}
                  </a>
                  <div
                    class="base-button base-button--action py-5 fsc-11 gap-10"
                    v-if="remoteAndAddedFile"
                    @click="clearFile"
                  >
                    <img
                      :src="require(`@/assets/icons/crist.svg`)"
                      width="13.5"
                      height="13.5"
                    />
                    <span class="fsc-14">Удалить файл</span>
                  </div>
                  <span class="fsc-12" v-if="remoteAndAddedFile">или</span>
                  <label
                    class="base-button base-button--action fsc-11 py-5 gap-10"
                  >
                    <img
                      :src="require(`@/assets/icons/upload.svg`)"
                      width="12"
                      height="12.8"
                    />
                    <span class="fsc-14"> Добавить файл</span>
                    <input
                      type="file"
                      @change="uploadFile"
                      accept="image/*,.pdf"
                      class="d-none"
                    />
                  </label>
                  <span class="text-muted fsc-12" v-if="!remoteAndAddedFile">
                    Файл не выбран
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            @click="$modal.show('addNewOsagoModal')"
            class="fsc-14 bg-primary cursor-pointer rounded-10 text-center py-10 px-15 flex-grow-0"
            style="max-width: 165px; width: 100%"
            v-html="dataLang['vehicle_modal_security_btns_get_osago']"
          ></div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex align-items-center gap-15 mb-5">
          <div class="system-block">
            <p class="fw-bolder fsc-12 mb-15">
              {{ dataLang['vehicle_modal_security_kasko_title'] }}
            </p>
            <div class="">
              <div class="d-flex mb-10 gap-10">
                <div ref="vehicle_kasko__policy">
                  <BaseInputNew
                    placeholder=""
                    :width="'245'"
                    input-name="vehicle_kasko__policy"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicle_kasko__policy"
                    :name-sign="dataLang['vehicle_modal_security_osago_policy']"
                  >
                  </BaseInputNew>
                </div>
                <div
                  class="d-flex gap-5 justify-content-between align-items-center"
                >
                  <span class="text-muted fsc-12">
                    {{ dataLang['vehicle_modal_security_osago_policy_from'] }}
                  </span>
                  <div>
                    <BaseDatePicker
                      v-model="dataTimeSelect.vehicle_kasko__policy_from"
                      type="date"
                      class="fsc-11 idiots-class-for-input-date"
                      lang="ru"
                      style="width: 110px"
                      value-type="format"
                      :format="userSetting.company__tempalte_date"
                      @change="
                        (date) =>
                          changeDate(
                            date,
                            'vehicle_kasko__policy_from',
                            {},
                            true
                          )
                      "
                    ></BaseDatePicker>
                  </div>
                </div>
                <div
                  class="d-flex gap-5 justify-content-between align-items-center"
                  ref="vehicle_kasko__policy_to"
                >
                  <span class="text-muted fsc-12">
                    {{ dataLang['vehicle_modal_security_osago_policy_to'] }}
                  </span>
                  <div>
                    <BaseDatePicker
                      v-model="dataTimeSelect.vehicle_kasko__policy_to"
                      type="date"
                      class="fsc-11 idiots-class-for-input-date"
                      lang="ru"
                      style="width: 110px"
                      value-type="format"
                      :format="userSetting.company__tempalte_date"
                      @change="
                        (date) =>
                          changeDate(date, 'vehicle_kasko__policy_to', {}, true)
                      "
                    ></BaseDatePicker>
                  </div>
                </div>
                <div ref="vehicle_kasko__cost">
                  <BaseInputNew
                    placeholder=""
                    :width="'265'"
                    input-name="vehicle_kasko__cost"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicle_kasko__cost"
                    :name-sign="dataLang['vehicle_modal_security_kasko_cost']"
                  >
                  </BaseInputNew>
                </div>
              </div>
              <div class="d-flex mb-10 gap-10">
                <div ref="vehicle_kasko__company_name" class="wp-100">
                  <BaseInputNew
                    placeholder=""
                    :width="'717'"
                    input-name="vehicle_kasko__company_name"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicle_kasko__company_name"
                    :name-sign="
                      dataLang['vehicle_modal_security_kasko_company_name']
                    "
                  >
                  </BaseInputNew>
                </div>
                <div ref="vehicle_kasko__company_phone">
                  <BaseInputNew
                    placeholder=""
                    :width="'140'"
                    input-name="vehicle_kasko__company_phone"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicle_kasko__company_phone"
                    inputRef="vehicle_kasko__company_phone"
                    inputMask="+{7}(000)000-00-00"
                    maxLength="16"
                    :name-sign="
                      dataLang[
                        'vehicle_modal_security_osago_policy_company_phone'
                      ]
                    "
                  >
                  </BaseInputNew>
                </div>
              </div>
              <div class="d-flex gap-10 wp-100">
                <div ref="vehicle_kasko__recipient">
                  <BaseInputNew
                    placeholder=""
                    :width="'875'"
                    input-name="vehicle_kasko__recipient"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="dataForModal.vehicle_kasko__recipient"
                    :name-sign="
                      dataLang['vehicle_modal_security_kasko_recipient']
                    "
                  >
                  </BaseInputNew>
                </div>
              </div>
            </div>
          </div>
          <div
            @click="$modal.show('addNewKaskoModal')"
            class="fsc-14 bg-primary cursor-pointer rounded-10 text-center py-10 px-15 flex-grow-0"
            style="max-width: 165px; width: 100%"
            v-html="dataLang['vehicle_modal_security_btns_get_kasko']"
          ></div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex align-items-center gap-15 mb-5">
          <div
            class="d-flex align-items-center flex-grow-1"
            style="max-width: 1034px"
          >
            <div
              class="d-flex align-items-center gap-15"
              style="max-width: 100%"
            >
              <div
                class="d-flex flex-column py-10 px-15 border rounded-5 border-disabled gap-0"
                style="height: 78px"
              >
                <div class="d-flex gap-10">
                  <div>
                    <BaseCheckboxV2
                      placeholder=""
                      class="mb-10"
                      input-name="vehicles__is_active_fines"
                      v-model="dataForModal.vehicles__is_active_fines"
                      @oncheckeds="updateValue"
                      >{{ '' }}</BaseCheckboxV2
                    >
                  </div>

                  <div class="d-flex flex-column gap-5">
                    <div
                      class="d-flex fsc-12 lh-sm justify-content-between"
                      style="max-width: 440px"
                    >
                      <span class="fsc-12 fw-bolder text-system-primary lh-sm"
                        >Контролировать штрафы и платные дороги</span
                      >
                      <span
                        class="d-inline-flex align-items-center fsc-12 fw-medium lh-sm px-5 text-white"
                        style="
                          background-color: #0065db;
                          padding-top: 3px;
                          padding-bottom: 3px;
                        "
                        >({{
                          settingGlobalData.fineCostForMonth
                        }}
                        руб.мес.)</span
                      >
                    </div>
                    <div class="fsc-11 lh-sm fw-normal" style="max-width: 100%">
                      Автоматическая ежедневная загрузка всех штрафов и проездов
                      по платным дорогам и мгновенное начисление
                      клиентам-нарушителям.
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex py-10 px-15 border rounded-5 border-disabled gap-10"
                style="height: 78px"
              >
                <div>
                  <label
                    class="d-flex align-items-center justify-content-center border border-1 border-disabled position-relative"
                    for="check1"
                    style="height: 24px; width: 24px"
                  >
                    <input
                      class="checkbox-custom"
                      type="checkbox"
                      id="check1"
                    />
                    <span
                      class="d-inline-flex align-items-center justify-content-center"
                      style="height: 14px; width: 14px; visibility: hidden"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.7979 0.942592C14.0301 1.1151 14.0678 1.42974 13.882 1.64537L5.26661 11.6454C5.17093 11.7564 5.02885 11.8244 4.87592 11.8322C4.723 11.8401 4.5737 11.7871 4.4654 11.6866L0.157711 7.68657C-0.0525705 7.4913 -0.0525705 7.17472 0.157712 6.97946C0.367994 6.7842 0.708928 6.7842 0.91921 6.97946L4.8015 10.5844L13.0411 1.02068C13.2268 0.805048 13.5657 0.770087 13.7979 0.942592Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </label>
                </div>
                <div class="d-flex flex-column fsc-12 lh-sm gap-5">
                  <div class="d-flex align-items-start fsc-12 lh-sm mb-0">
                    <div class="fsc-12 lh-sm fw-bolder">
                      GPS мониторинг
                      <span class="fw-medium"
                        >(объект на карте + автоблокировка двигателя по
                        отрицательному балансу клиента)</span
                      >
                    </div>
                    <span
                      data-v-0e5e02c8=""
                      class="d-inline-flex align-items-center fsc-12 fw-medium lh-sm px-5 text-white"
                      style="
                        background-color: rgb(0, 101, 219);
                        padding-top: 3px;
                        padding-bottom: 3px;
                        width: 146px;
                      "
                      >({{ settingGlobalData.gpsCostForMonth }} руб.мес.)</span
                    >
                  </div>
                  <div class="d-flex align-items-center gap-10">
                    <div class="d-flex align-items-center gap-5">
                      <div class="d-flex align-items-center gap-5">
                        <span class="text-muted fsc-11">ID трекера SIRIUS</span>
                        <div
                          class="border border-disabled py-5 px-10 fsc-11 lh-sm"
                          style="width: 120px"
                        >
                          7043261180
                        </div>
                      </div>
                    </div>

                    <div class="d-flex align-items-center gap-5">
                      <div class="d-flex align-items-center gap-5">
                        <span class="text-muted fsc-11">SIM</span>
                        <div
                          class="border border-disabled py-5 px-10 fsc-11 lh-sm"
                          style="width: 120px"
                        >
                          +7 (901) 234-56-78
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            @click="$modal.show('addNewGpsModal')"
            class="fsc-14 bg-primary cursor-pointer rounded-10 text-center py-10 px-15 flex-grow-0"
            style="max-width: 165px; width: 100%"
            v-html="dataLang['vehicle_modal_security_btns_get_gps']"
          ></div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex align-items-center gap-15">
          <div class="system-block flex-grow-1" style="max-width: 1034px">
            <p class="fw-bolder fsc-12 mb-10">
              {{ dataLang['vehicle_modal_security_lighthouses_title'] }}
            </p>
            <div class="gap-25 d-flex">
              <div class="">
                <div ref="vehicle_lighthouses__sim" class="mb-10">
                  <BaseInputNew
                    placeholder=""
                    :width="'250'"
                    input-name="vehicle_lighthouses__sim"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="lighhouseList[0].vehicle_lighthouses__sim"
                    :additionalParams="lighhouseList[0]"
                    inputRef="vehicle_kasko__company_phone1"
                    inputMask="+{7}(000)000-00-00"
                    maxLength="16"
                    :name-sign="
                      dataLang['vehicle_modal_security_lighthouses_sim']
                    "
                  >
                  </BaseInputNew>
                </div>
                <div ref="vehicle_lighthouses__sim" class="mb-10">
                  <BaseInputNew
                    placeholder=""
                    :width="'250'"
                    input-name="vehicle_lighthouses__sim"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="lighhouseList[1].vehicle_lighthouses__sim"
                    :additionalParams="lighhouseList[1]"
                    inputRef="vehicle_kasko__company_phone2"
                    inputMask="+{7}(000)000-00-00"
                    maxLength="16"
                    :name-sign="
                      dataLang['vehicle_modal_security_lighthouses_sim']
                    "
                  >
                  </BaseInputNew>
                </div>
                <div ref="vehicle_lighthouses__sim">
                  <BaseInputNew
                    placeholder=""
                    :width="'250'"
                    input-name="vehicle_lighthouses__sim"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="lighhouseList[2].vehicle_lighthouses__sim"
                    :additionalParams="lighhouseList[2]"
                    inputRef="vehicle_kasko__company_phone3"
                    inputMask="+{7}(000)000-00-00"
                    maxLength="16"
                    :name-sign="
                      dataLang['vehicle_modal_security_lighthouses_sim']
                    "
                  >
                  </BaseInputNew>
                </div>
              </div>
              <div class="">
                <div
                  class="d-flex gap-5 justify-content-between align-items-center mb-10"
                >
                  <span class="text-muted fsc-12">
                    {{ dataLang['vehicle_modal_security_lighthouses_date'] }}
                  </span>
                  <div>
                    <BaseDatePicker
                      v-model="lighhouseList[0].vehicle_lighthouses__added_at"
                      type="date"
                      class="fsc-11 idiots-class-for-input-date"
                      lang="ru"
                      style="width: 112px"
                      value-type="format"
                      :format="userSetting.company__tempalte_date"
                      @change="
                        (date) =>
                          changeDate(
                            date,
                            'vehicle_lighthouses__added_at',
                            lighhouseList[0]
                          )
                      "
                    ></BaseDatePicker>
                  </div>
                </div>
                <div
                  class="d-flex gap-5 justify-content-between align-items-center mb-10"
                >
                  <span class="text-muted fsc-12">
                    {{ dataLang['vehicle_modal_security_lighthouses_date'] }}
                  </span>
                  <div>
                    <BaseDatePicker
                      v-model="lighhouseList[1].vehicle_lighthouses__added_at"
                      type="date"
                      class="fsc-11 idiots-class-for-input-date"
                      lang="ru"
                      style="width: 112px"
                      value-type="format"
                      :format="userSetting.company__tempalte_date"
                      @change="
                        (date) =>
                          changeDate(
                            date,
                            'vehicle_lighthouses__added_at',
                            lighhouseList[1]
                          )
                      "
                    ></BaseDatePicker>
                  </div>
                </div>
                <div
                  class="d-flex gap-5 justify-content-between align-items-center"
                >
                  <span class="text-muted fsc-12">
                    {{ dataLang['vehicle_modal_security_lighthouses_date'] }}
                  </span>
                  <div>
                    <BaseDatePicker
                      v-model="lighhouseList[2].vehicle_lighthouses__added_at"
                      type="date"
                      class="fsc-11 idiots-class-for-input-date"
                      lang="ru"
                      style="width: 112px"
                      value-type="format"
                      :format="userSetting.company__tempalte_date"
                      @change="
                        (date) =>
                          changeDate(
                            date,
                            'vehicle_lighthouses__added_at',
                            lighhouseList[2]
                          )
                      "
                    ></BaseDatePicker>
                  </div>
                </div>
              </div>
              <div class="">
                <div ref="vehicle_lighthouses__change_after_days" class="mb-10">
                  <BaseInputNew
                    placeholder=""
                    :width="'194'"
                    input-name="vehicle_lighthouses__change_after_days"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="
                      lighhouseList[0].vehicle_lighthouses__change_after_days
                    "
                    :additionalParams="lighhouseList[0]"
                    :name-sign="
                      dataLang['vehicle_modal_security_lighthouses_days']
                    "
                  >
                  </BaseInputNew>
                </div>
                <div ref="vehicle_lighthouses__change_after_days" class="mb-10">
                  <BaseInputNew
                    placeholder=""
                    :width="'194'"
                    input-name="vehicle_lighthouses__change_after_days"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="
                      lighhouseList[1].vehicle_lighthouses__change_after_days
                    "
                    :additionalParams="lighhouseList[1]"
                    :name-sign="
                      dataLang['vehicle_modal_security_lighthouses_days']
                    "
                  >
                  </BaseInputNew>
                </div>
                <div ref="vehicle_lighthouses__change_after_days">
                  <BaseInputNew
                    placeholder=""
                    :width="'194'"
                    input-name="vehicle_lighthouses__change_after_days"
                    @input="updateValue"
                    :horizontalTitle="true"
                    :value="
                      lighhouseList[2].vehicle_lighthouses__change_after_days
                    "
                    :additionalParams="lighhouseList[2]"
                    :name-sign="
                      dataLang['vehicle_modal_security_lighthouses_days']
                    "
                  >
                  </BaseInputNew>
                </div>
              </div>
            </div>
          </div>
          <div
            @click="$modal.show('addNewLighthouseModal')"
            class="fsc-14 bg-primary cursor-pointer rounded-10 text-center py-10 px-15 flex-grow-0"
            style="max-width: 165px; width: 100%"
            v-html="dataLang['vehicle_modal_security_btns_get_lighthouse']"
          ></div>
        </div>
      </div>
    </div>
    <AddNewOsagoModal
      :optionalParams="{ id: currentOpenedId }"
    ></AddNewOsagoModal>
    <AddNewKaskoModal
      :optionalParams="{ id: currentOpenedId }"
    ></AddNewKaskoModal>
    <AddNewGpsModal :optionalParams="{ id: currentOpenedId }"></AddNewGpsModal>
    <AddNewLighthouseModal
      :optionalParams="{ id: currentOpenedId }"
    ></AddNewLighthouseModal>
    <gallery
      v-if="loadGallery"
      :images="images"
      :index="index"
      @close="index = null"
    ></gallery>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import ObjectsService from '@/api/ObjectsService'
import StorageFileService from '@/api/StorageFileService'

import EventBus from '@/event-bus'
import AddNewOsagoModal from '@/components/vehicles-page-single-modal-inner/AddNewOsagoModal.vue'
import AddNewKaskoModal from '@/components/vehicles-page-single-modal-inner/AddNewKaskoModal.vue'
import AddNewGpsModal from '@/components/vehicles-page-single-modal-inner/AddNewGpsModal.vue'
import AddNewLighthouseModal from '@/components/vehicles-page-single-modal-inner/AddNewLighthouseModal.vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import VueGallery from 'vue-gallery'
import GeneralService from '@/api/GeneralService'
export default {
  components: {
    AddNewOsagoModal,
    AddNewKaskoModal,
    AddNewGpsModal,
    AddNewLighthouseModal,
    gallery: VueGallery
  },

  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    },

    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      updatedValues: {},
      dataForModal: {},
      remoteAndAddedFile: null,
      loadingState: true,
      settingGlobalData: {},
      images: [],
      index: null,
      loadGallery: false,
      dataTimeSelect: {
        vehicle_kasko__policy_to: '',
        vehicle_kasko__policy_from: '',
        vehicle_osago__policy_to: '',
        vehicle_osago__policy_from: ''
      },
      lighhouseList: [],
      lighhouseListForSend: []
    }
  },
  methods: {
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      if (Object.keys(this.updatedValues).length) {
        ObjectsService.update(this.currentOpenedId, {
          ...this.updatedValues,
          ...{
            lighthouses: this.lighhouseListForSend,
            vehicle_gps__phone: '+7(000)000-00-00'
          }
        })
          .then((data) => {
            console.log(data)
            EventBus.$emit('contentLoaded')
            EventBus.$emit('updateContentData')
            this.$toast.success(data.data.message)
            callback(true, nameTab, oldTab, newTab, flagIsClose)
          })
          .catch((error) => {
            console.log(error)
            this.$toast.error(error.response.data.message)
            callback(false, nameTab, oldTab, newTab)
          })
      } else {
        callback(true, '', oldTab, newTab, flagIsClose)
      }
    },

    uploadFile(e) {
      this.$emit('loadingModal', true)
      StorageFileService.upload({ file: e.target.files[0], type: 'file' })
        .then((data) => {
          console.log(data.data.data)
          this.$toast.success(data.data.message)
          this.remoteAndAddedFile = data.data.data.url
          this.updatedValues.vehicle_osago__file = data.data.data.url
        })
        .catch((error) => {
          console.log(error)
          this.$toast.error(error.response.data.message)
        })
      this.$emit('loadingModal', false)
      // StorageFileService.upload()
    },
    clearFile() {
      this.remoteAndAddedFile = null
      this.updatedValues.vehicle_osago__file = ''
    },

    changeDate(date, nameField, additionalParams, isSimpleInputDate) {
      console.log(
        date,
        nameField,
        additionalParams,
        this.userSetting.company__tempalte_date,
        dayjs(date, `${this.userSetting.company__tempalte_date}`).utc(true)
      )
      this.updatedValues[nameField] = date
        ? dayjs(date, `${this.userSetting.company__tempalte_date}`)
            .utc(true)
            .utcOffset(Number(this.userSetting.company__utc))
            .format(
              `${this.userSetting.company__tempalte_date} ${this.userSetting.company__tempalte_time}`
            )
        : ''

      if (nameField == 'vehicle_lighthouses__added_at') {
        this.lighhouseListForSend.find(
          (x) => x.id == additionalParams.vehicle_lighthouses__id
        )[nameField] = date
          ? dayjs(date, `${this.userSetting.company__tempalte_date}`)
              .utc(true)
              .utcOffset(Number(this.userSetting.company__utc))
              .format(
                `${this.userSetting.company__tempalte_date} ${this.userSetting.company__tempalte_time}`
              )
          : ''
      }
    },

    updateValue(data) {
      console.log(data)
      this.updatedValues[data.name] = data.value
      if (
        data.name == 'vehicle_lighthouses__change_after_days' ||
        data.name == 'vehicle_lighthouses__sim'
      ) {
        this.lighhouseListForSend.find(
          (x) => x.id == data.vehicle_lighthouses__id
        )[data.name] = data.value
        console.log(this.lighhouseListForSend)
      }
      if (this.$refs[data.name]) {
        this.$refs[data.name].classList.remove('input-error')
      }
    },

    ...mapActions('transportVehiclesModule', ['getHealth', 'getTitle'])
  },
  mounted() {
    dayjs.extend(utc)
    dayjs.extend(customParseFormat)
  },

  created() {
    this.$emit('loadingModal', true)
    this.getHealth(this.currentOpenedId)
    this.getTitle(this.currentOpenedId)

    Promise.all([
      ObjectsService.getAll({ url: `/${this.currentOpenedId}/osago` })
        .then((data) => {
          console.log(data)
          Object.assign(this.dataForModal, data.data.data.rows[0])
          this.dataTimeSelect.vehicle_osago__policy_to = data.data.data.rows[0]
            .vehicle_osago__policy_to
            ? dayjs(data.data.data.rows[0].vehicle_osago__policy_to).format(
                `${this.userSetting.company__tempalte_date}`
              )
            : ''

          this.dataTimeSelect.vehicle_osago__policy_from = data.data.data
            .rows[0].vehicle_osago__policy_from
            ? dayjs(data.data.data.rows[0].vehicle_osago__policy_from).format(
                `${this.userSetting.company__tempalte_date}`
              )
            : ''
          this.remoteAndAddedFile = data.data.data.rows[0].vehicle_osago__file
        })
        .catch((err) => {
          // console.log(err)
        }),
      ObjectsService.getAll({ url: `/${this.currentOpenedId}/kasko` })
        .then((data) => {
          console.log(data)
          Object.assign(this.dataForModal, data.data.data.rows[0])
          this.dataTimeSelect.vehicle_kasko__policy_to = data.data.data.rows[0]
            .vehicle_kasko__policy_to
            ? dayjs(data.data.data.rows[0].vehicle_kasko__policy_to).format(
                `${this.userSetting.company__tempalte_date}`
              )
            : ''
          this.dataTimeSelect.vehicle_kasko__policy_from = data.data.data
            .rows[0].vehicle_kasko__policy_from
            ? dayjs(data.data.data.rows[0].vehicle_kasko__policy_from).format(
                `${this.userSetting.company__tempalte_date}`
              )
            : ''
        })
        .catch((err) => {
          // console.log(err)
        }),
      ObjectsService.getAll({ url: `/${this.currentOpenedId}/gps` })
        .then((data) => {
          console.log(data)
          Object.assign(this.dataForModal, data.data.data.rows[0])
        })
        .catch((err) => {
          // console.log(err)
        }),
      ObjectsService.getAll({ url: `/${this.currentOpenedId}` })
        .then((data) => {
          console.log(data)
          this.dataForModal.vehicles__is_active_fines =
            data.data.data.rows[0].vehicles__is_active_fines
        })
        .catch((err) => {
          // console.log(err)
        }),
      ObjectsService.getAll({ url: `/${this.currentOpenedId}/lighthouses` })
        .then((data) => {
          console.log(data)
          Object.assign(this.dataForModal, data.data.data.rows[0])
          data.data.data.rows.forEach((element) => {
            let assingDate = dayjs(
              element.vehicle_lighthouses__added_at
            ).format(this.userSetting.company__tempalte_date)
            // vehicle_lighthouses__added_at
            this.lighhouseList.push({
              ...element,
              ...{ vehicle_lighthouses__added_at: assingDate }
            })
            this.lighhouseListForSend.push({
              id: element.vehicle_lighthouses__id
            })
          })
        })
        .catch((err) => {
          // console.log(err)
        }),

      // settingGlobalData
      GeneralService.get('/crm/settings')
        .then((data) => {
          console.log(data)
          this.settingGlobalData = data.data.data
        })
        .catch((err) => {
          // console.log(err)
        })
    ]).then(() => {
      console.log('This end')
      this.$emit('loadingModal', false)
      this.loadingState = false
      console.log(this.dataTimeSelect)
      this.loadGallery = true
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('transportVehiclesModule', ['numPages']),
    ...mapState('userSettingModule', ['userSetting'])
  }
}
</script>

<style lang="scss" scoped>
.text-black {
  color: black !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.rounded-8 {
  border-radius: 8px;
}

.w-18 {
  width: 18px;
}

.h-18 {
  height: 18px;
}

.button-custom {
  height: 29px;
  background: linear-gradient(
      180deg,
      rgba(93, 92, 92, 0.2) 19.38%,
      rgba(215, 215, 215, 0.18) 100%
    ),
    #fff;
  border-color: #ededed;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.09), 0px 2px 1px 0px rgba(0, 0, 0, 0.05),
    0px 4px 2px 0px rgba(0, 0, 0, 0.01), 0px 7px 2px 0px rgba(0, 0, 0, 0);
}

.checkbox-custom {
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  border: 0 !important;
  outline: 0 !important;
  position: absolute;
  visibility: hidden;
}

.checkbox-custom:checked + span {
  visibility: visible !important;
}
</style>
