<template>
  <div class="position-relative">
    <BaseTopLine> </BaseTopLine>
    <div
      class="py-10 px-15 d-flex gap-15 align-items-end border-top"
      style="background-color: #1d1e2c"
      ref="topFilter"
    >
      <div class="d-flex gap-5 align-items-center flex-grow-1">
        <span class="text-white fsc-11">{{
          dataLang['reestr_fines_super_text1']
        }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 100%; flex: 1"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="vehicle_fines__bill_id"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11 px-20" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-5 align-items-center flex-grow-1">
        <span class="text-white fsc-11">{{
          dataLang['reestr_fines_super_text2']
        }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 100%; flex: 1"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="vehicle_fines__bill_id"
            inputRef="clients__phone"
            inputMask="a000aa000"
            maxLength="9"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11 px-20" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-5 align-items-center flex-grow-1">
        <span class="text-white fsc-11">{{
          dataLang['reestr_fines_super_text3']
        }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 100%"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="vehicle_fines__bill_id"
            value=""
            nameSign=""
          />
          <div class="btn btn-sm btn-primary fsc-11 px-20" @click="loadData">
            {{ dataLang['reestr_fines_text9'] }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="position-relative h-100 container-fluid"
      style="min-height: 250px"
    >
      <BaseLoader v-if="localLoading"></BaseLoader>
      <div class="d-flex justify-content-end align-items-center mt-15 mb-10">
        <div class="d-flex gap-10">
          <div
            class="base-button base-button--action"
            @click="
              () => {
                activeSelectRowsMultiple = []
                payInOneClick()
              }
            "
          >
            {{ dataLang['reestr_fines_super_text5'] }}
          </div>
          <div
            class="base-button base-button--action"
            @click="checkAcceptCheckbox('payInOneClick')"
          >
            {{ dataLang['reestr_fines_super_text6'] }}
          </div>
          <!-- <div class="base-button base-button--action" @click="toExcel">
              <img
                :src="require(`@/assets/icons/to-exel.svg`)"
                width="16"
                height="16"
              />
              <span class="fsc-11">в Excel</span>
            </div> -->
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-center mt-15 mb-10"
      >
        <div class="d-flex gap-15 align-items-center">
          <div
            class="btn btn-sm btn-thrity text-white fsc-14"
            @click="loadData(1)"
          >
            {{ dataLang['register_btn_filter_on'] }}
          </div>
          <div
            class="text-decoration-underline fsc-14 text-dark"
            style="cursor: pointer"
            @click="resetFilter"
          >
            {{ dataLang['register_btn_filter_off'] }}
          </div>
        </div>
        <BasePagination
          :page-count="numPages"
          :pagination-params="paginationFinesStateParams"
          :showLimitInput="false"
          :page-range="10"
          :margin-pages="0"
          :break-view-class="''"
          :click-handler="loadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        >
        </BasePagination>
      </div>
      <div class="overflow-auto" style="min-height: 400px">
        <ve-table
          style="width: 100%"
          :columns="columns"
          :table-data="dataTable"
          :border-x="true"
          :border-y="true"
          row-key-field-name="vehicle_fines_super__id"
          ref="mainTable"
          :eventCustomOption="eventCustomOption"
          v-if="!localLoading"
          :checkbox-option="{
            selectedRowChange: selectedRowChange,
            selectedAllChange: selectedAllChange,
            hideSelectAll: false
          }"
          :clickHighlight="false"
          :cell-style-option="cellStyleOption"
          :rowStyleOption="{
            hoverHighlight: true
          }"
          :cellSelectionOption="{ enable: false }"
        >
        </ve-table>
      </div>
    </div>
    <BaseModal
      name="acceptDelete"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDelete')"
          ></button>
        </div>
        <p class="text-center">{{ dataLang.confirm_dialog_delete }}</p>
        <div class="d-flex justify-content-center mt-10">
          <div class="btn btn-sm btn-danger" @click="deleteRow">
            {{ dataLang.confirm_btn__btn_yes }}
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="withoutOptionsModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="320"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 py-25 px-20 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('withoutOptionsModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold">
            {{ dataLang['reestr_fines_text_error2'] }}
          </p>
          <div class="d-flex justify-content-center mt-10">
            <div
              class="btn btn-sm btn-primary px-20"
              @click="$modal.hide('withoutOptionsModal')"
            >
              {{ dataLang['reestr_fines_text72'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="oneClickModal"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
      @beforeClose="() => (lastBillNumber = '')"
    >
      <div class="bg-white rounded-25 py-25 px-20 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('oneClickModal')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold">
            {{ dataLang['reestr_fines_text449'] }}
          </p>
          <div
            class="fsc-14 mb-15"
            v-html="transformTextTranslateComputed"
          ></div>
          <BaseInputNew
            placeholder="Начальный номер платежного поручения"
            style="width: 100%; flex: 1"
            @input="(data) => (lastBillNumber = data.value)"
            inputClass="fsc-14"
            :additionalParams="{ type: 'like' }"
            inputName="lastBillNumber"
            type="number"
            inputType="number"
            value=""
            nameSign=""
          />

          <div
            class="fsc-13 mt-15"
            v-html="dataLang['reestr_fines_text448']"
          ></div>
          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('oneClickModal')"
            >
              {{ dataLang['reestr_fines_text48'] }}
            </div>

            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="payOneClickSend"
            >
              {{ dataLang['reestr_fines_super_text229'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      name="acceptFine"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
      @beforeClose="() => (lastBillNumber = '')"
    >
      <div class="bg-white rounded-25 py-25 px-20 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptFine')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold">
            {{ dataLang['reestr_fines_super_text24'] }}
          </p>
          <div
            class="fsc-14 mb-15 text-center"
            v-html="dataLang['reestr_fines_super_text25']"
          ></div>
          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('acceptFine')"
            >
              {{ dataLang['reestr_fines_super_text26'] }}
            </div>

            <div
              class="btn btn-sm px-20 btn-primary fsc-14 text-uppercase"
              @click="acceptFineSend"
            >
              {{ dataLang['reestr_fines_super_text27'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import GeneralService from '@/api/GeneralService'

export default {
  components: {
    VeTable
  },

  data() {
    return {
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [
        { field: 'vehicle_fines_statuses__id', value: '2', operator: '!=' }
      ],
      updatedValues: {},
      computedAllPage: 1,
      currentPage: 1,
      nameValue: '',
      settingOneClick: {},
      activePagePagination: {},
      currentId: null,
      localLoadingModal: false,
      pincode: '',
      copiedDataForFilter: [],
      galleryExeples: null,
      transformTextTranslateComputed: '',
      lastBillNumber: '',
      selectsDataForModal: {
        places: []
      },
      selectsFromModal: {
        places: []
      },
      selectsData: {
        discount: [],
        statuses: [],
        clients: []
      },
      selectsFilter: { discount: [], statuses: [], clients: [] },
      gelleryList: [],
      eventCustomOption: {},
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (column.field == 'vehicle_fines_statuses__name') {
            return row.settings?.vehicle_fines_statuses__name
          }
          if (column.field == 'vehicle_fines_super__last_bill_date') {
            return row.settings?.vehicle_fines_super__last_bill_date
          }
          if (column.field == 'vehicle_fines_super__gis_discount_uptodate') {
            return row.settings?.vehicle_fines_super__gis_discount_uptodate
          }
        }
      }
    }
  },
  methods: {
    checkAcceptCheckbox(name) {
      if (this.activeSelectRowsMultiple.length) {
        this.$modal.show(name)
        switch (name) {
          case 'payInOneClick':
            this.payInOneClick()
            break
        }
      } else {
        this.$modal.show('withoutOptionsModal')
      }
    },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },

    payOneClickSend() {
      if (this.lastBillNumber.length) {
        GeneralService.editOrCreate(`/fines-super/pay/txt`, {
          lastBillNumber: this.lastBillNumber,
          ids: this.activeSelectRowsMultiple
        })
          .then((data) => {
            console.log(data.data)
            const filename = new Date().toISOString()
            const a = document.createElement('a')
            a.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
              data.data
            )}`
            a.download = `${filename}.txt`
            document.body.appendChild(a)
            a.click()
            a.remove()
            this.$modal.hide('payInOneClick')
            this.$modal.hide('oneClickModal')
            this.loadData()
            this.$modal.show('acceptFine')
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error(e.response.data.message)
          })
          .finally(() => {
            this.$modal.hide('cancelFinesModal')
            this.localLoading = false
          })
      } else {
        this.$toast.error(this.dataLang['reestr_fines_text450'])
      }
    },
    cancelFines() {
      GeneralService.editOrCreate(`/fines/cancel`, {
        finesIds: this.activeSelectRowsMultiple
      })
        .then((data) => {
          console.log(data)
          this.$toast.success(data.data.data.message)
          this.loadData()
        })
        .catch((e) => {
          console.log(e)
          this.$toast.error(e.response.data.message)
        })
        .finally(() => {
          this.$modal.hide('cancelFinesModal')
          this.localLoading = false
        })
    },
    payInOneClick() {
      GeneralService.editOrCreate(`/fines-super/pay/modal`, {
        ids: this.activeSelectRowsMultiple
      })
        .then((data) => {
          console.log(data)
          this.$modal.show('oneClickModal')
          this.transformTextTranslateComputed = this.computedTranslate(
            data.data.data,
            this.dataLang['reestr_fines_text4']
          )
        })
        .catch((e) => {
          console.log(e)
          this.$toast.error(e.response.data.message)
        })
    },
    computedTranslate(values, tranaslate) {
      let keys = Object.keys(values)
      let tranaslateLocal = tranaslate
      keys.forEach((keyName) => {
        console.log(`\\${keyName}\\`)
        tranaslateLocal = tranaslateLocal.replace(
          `\\${keyName}\\`,
          values[keyName]
        )
      })
      console.log(tranaslateLocal)
      return tranaslateLocal
    },
    acceptFineSend() {
      GeneralService.editOrCreate(`/fines-super/pay/confirm`, {})
        .then((data) => {
          console.log(data)
          this.$toast.success(data.data.message)
        })
        .catch((e) => {
          console.log(e)
          this.$toast.error(e.response.data.message)
        })
        .finally(() => {
          this.$modal.hide('acceptFine')
        })
    },
    resetFilter() {
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.$refs.topFilter
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.filter = [
        { field: 'vehicle_fines_statuses__id', value: '2', operator: '!=' }
      ]
      this.selectsFilter.discount = []
      this.selectsFilter.statuses = []
      this.loadData()
    },

    // On click
    // Navigate to: "Авторизация / Данные введены";
    // Animate: Smart animate;
    // Spring: { mass: 1, stiffness: 400, damping: 30 };

    closemodal() {
      this.currentId = null
      this.updatedValues = {}
      this.activeSelectRowsMultiple = []
    },
    deleteActionAndModal() {
      if (this.activeSelectRowsMultiple.length) {
        this.$modal.show('acceptDelete')
      } else {
        this.$modal.show('withoutOptionsModal')
      }
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.activeSelectRowsMultiple = []
    },
    deleteRow(nameModal) {
      GeneralService.delete(`/objects/` + this.activeSelectRowsMultiple, {
        ...(this.pincode && { pincode: this.pincode })
      })
        .then((data) => {
          this.loadData()
          console.log(data)
          this.$toast.success(data.data.message)
          this.$modal.hide('acceptDeleteWithPin')
          this.$modal.hide('acceptDelete')
        })
        .catch((e) => {
          console.log(e)
          if (e.response.status == 409) {
            this.$modal.show('acceptDeleteWithPin')
            this.$toast.error(e.response.data.message)
          } else if (e.response.status == 400) {
            this.$toast.error(e.response.data.message)
          }
        })
    },
    loadData() {
      this.localLoading = true
      Promise.all([
        GeneralService.get(`/selects/field/filter_fines_table_select_discount`)
          .then((data) => {
            // this.selects.push(data.data.data.rows)

            this.selectsData.discount = this.prepareLabelsForDefaultSelects(
              data.data.data.rows,
              'allSelects',
              true
            ).filter_fines_table_select_discount
            console.log(this.selectsData.discount)
          })
          .catch((e) => {
            console.log(e)
          }),
        GeneralService.get(
          `/clients?joins=company,statistics,verifications,comment`
        )
          .then((data) => {
            this.selectsData.clients = data.data.data.rows
            console.log(data)
          })
          .catch((e) => {
            console.log(e)
          })

        // /fines/table/main?fields=vehicle_fines_statuses__id,vehicle_fines_statuses__name&uniqueBy=vehicle_fines_statuses__id
      ]).then(() => {
        GeneralService.get(`fines-super/table/main`, {
          params: {
            ...this.paginationFinesStateParams,
            ...(this.filter.length && { where: JSON.stringify(this.filter) })
          }
        })
          .then((data) => {
            this.getFinesAll(data.data.data)
            console.log(data)
            this.dataTable = data.data.data.rows
            this.localLoading = false
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error('Неизвестная ошибка при загрузке таблицы')
          })
      })
    },
    // fillFilter(data) {
    //   let newValueFilter =
    //     data.type == 'like' ? `;percent;${data.value};percent;` : data.value
    //   if (!this.filter.find((x) => x.field == data.name)) {
    //     this.filter.push({
    //       field: data.name,
    //       value: newValueFilter,
    //       ...(data.type == 'like' && { operator: 'like' }),
    //       ...(data.operator && { operator: data.operator })
    //     })
    //   } else {
    //     this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
    //       newValueFilter
    //     console.log(this.filter)
    //   }
    //   console.log(this.filter, data)
    // },

    fillFilter(data) {
      this.topFilterParams = []
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(data.operator && { operator: data.operator }),
          ...(data.mod && { mod: data.mod })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        if (data.operator) {
          this.filter[
            this.filter.findIndex((x) => x.field == data.name)
          ].operator = data.operator
        }

        console.log(this.filter)
      }
      console.log(this.filter, data)
    },

    openCard(datas) {
      console.log(datas)

      GeneralService.get(`/fines-super/card/${datas.vehicle_fines_super__id}`)
        .then((data) => {
          console.log(data)
        })
        .catch((e) => {
          console.log(e)
          this.$toast.error(e.response.data.message)
        })
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    ...mapActions('finesPagination', ['getFinesAll'])
  },
  mounted() {
    // {field:"vehicle_fines_super__status_id", value:"2", operator:"!="}
  },
  created() {
    Promise.all([
      //   }),
    ]).then(() => {
      this.loadData()
      //   GeneralService.editOrCreate(`/integrators`, {
      //     integrators__company_id: 1,
      //     integrators__percent: 2
      //   })
      //     .then((data) => {
      //       console.log(data)
      //     })
      //     .catch((e) => {
      //       console.log(e)
      //       this.$toast.error('Неизвестная ошибка')
      //     })
    })
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('finesPagination', ['paginationFinesStateParams']),
    ...mapGetters('finesPagination', ['numPages']),
    columns() {
      return [
        {
          field: '',
          key: 'a6',
          type: 'checkbox',
          title: '',
          width: '2%',
          align: 'center'
        },
        {
          title: this.dataLang['reestr_fines_super_text7'],
          children: [
            {
              field: 'vehicle_fines_super__created_at',
              key: '1723',
              title: this.dataLang['reestr_fines_super_text10'],
              align: 'center',
              width: '8%',
              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicle_fines_super__created_at
                      ? this.$time(row.vehicle_fines_super__created_at)
                          .utc(true)
                          .utcOffset(Number(this.userSetting.company__utc))
                          .format(
                            `${this.userSetting.company__tempalte_date} HH:mm`
                          )
                      : ''}
                  </span>
                )
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'vehicle_fines_super__employee_id',
              key: '163',
              title: this.dataLang['reestr_fines_super_text11'],
              align: 'center',
              width: '7%',
              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputRef={'clients__phFEone'}
                      inputName={'vehicle_fines_super__employee_id'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'employees__name',
              key: '1533',
              title: this.dataLang['reestr_fines_super_text12'],
              align: 'center',
              width: '8%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'employees__name'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'employees__phone',
              key: '172',
              title: this.dataLang['reestr_fines_super_text13'],
              align: 'center',
              width: '10%',
              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },

              // renderBodyCell: ({ row, column, rowIndex }, h) => {
              //   return this.dataLang[row.vehicle_types__code]
              // },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: this.dataLang['reestr_fines_super_text8'],
          children: [
            {
              field: 'vehicle_fines_super__type',
              key: '13746',
              title: this.dataLang['reestr_fines_super_text14'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>{this.dataLang[row.vehicle_fines_super__type]}</span>
                )
              }
            },
            {
              field: 'vehicles__gosnumber',
              key: '16531',
              title: this.dataLang['reestr_fines_super_text15'],
              align: 'center',
              width: '8%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },

              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputRef={'clients__phFEon2e'}
                      inputName={'vehicles__gosnumber'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'vehicle_fines_super__bill_id',
              key: '163111',
              title: this.dataLang['reestr_fines_super_text16'],
              align: 'center',
              width: '8%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputRef={'clients__phFEon2e'}
                      inputName={'vehicle_fines_super__bill_id'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'vehicle_fines_super__pay_bill_date',
              key: '16315',
              title: this.dataLang['reestr_fines_super_text17'],
              align: 'center',
              width: '8%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicle_fines_super__pay_bill_date
                      ? this.$time(row.vehicle_fines_super__pay_bill_date)
                          .utc(true)
                          .utcOffset(Number(this.userSetting.company__utc))
                          .format(`${this.userSetting.company__tempalte_date}`)
                      : ''}
                  </span>
                )
              }
            },
            {
              field: 'vehicle_fines_super__offense_date_time',
              key: '12631',
              title: this.dataLang['reestr_fines_super_text18'],
              align: 'center',
              width: '8%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicle_fines_super__offense_date_time
                      ? this.$time(row.vehicle_fines_super__offense_date_time)
                          .utc(true)
                          .utcOffset(Number(this.userSetting.company__utc))
                          .format(
                            `${this.userSetting.company__tempalte_date} HH:mm`
                          )
                      : ''}
                  </span>
                )
              }
            },
            {
              field: 'vehicle_fines_super__pay_bill_amount',
              key: '16311',
              title: this.dataLang['reestr_fines_super_text19'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'vehicle_fines_super__gis_discount_uptodate',
              key: '16331',
              title: this.dataLang['reestr_fines_super_text20'],
              align: 'center',
              width: '7%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicle_fines_super__gis_discount_uptodate
                      ? this.$time(
                          row.vehicle_fines_super__gis_discount_uptodate
                        ).format(`${this.userSetting.company__tempalte_date}`)
                      : ''}
                  </span>
                )
              }
            },
            {
              field: 'vehicle_fines_super__last_bill_date',
              key: '16431',
              title: this.dataLang['reestr_fines_super_text21'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: false,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicle_fines_super__last_bill_date
                      ? this.$time(
                          row.vehicle_fines_super__last_bill_date
                        ).format(`${this.userSetting.company__tempalte_date}`)
                      : ''}
                  </span>
                )
              }
            }
          ]
        },
        {
          key: '8123',
          title: this.dataLang['reestr_fines_text30'],
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>

                <div style={'height: 19.5px'}></div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <img
                onClick={() => {
                  this.openCard(row)
                }}
                style={'cursor: pointer'}
                src={require(`@/assets/icons/card.svg`)}
              />
            )
          }
        },
        {
          field: 'vehicle_fines_statuses__name',
          key: '9123',
          title: this.dataLang['reestr_fines_text31'],
          align: 'center',
          width: '5%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
                <BaseSelect
                  vModel={this.selectsFilter.statuses}
                  onInput={(e, w) => {
                    console.log(e)
                    this.fillFilter({
                      name: 'vehicle_fines_statuses__id',
                      value: e.value,
                      operator: e.operator
                    })
                  }}
                  style={'width: 100px'}
                  label={'label'}
                  placeholder={''}
                  id={'ads__id'}
                  selectLabel={''}
                  deselectLabel={''}
                  options={[
                    {
                      label: this.dataLang['fine_status_nopayed'],
                      value: 1,
                      operator: '='
                    },
                    {
                      label: this.dataLang['fine_status_payed'],
                      value: 2,
                      operator: '='
                    },
                    {
                      label: this.dataLang['fine_status_processing'],
                      value: 3,
                      operator: '='
                    },
                    {
                      label: this.dataLang['fine_status_canceling'],
                      value: 4,
                      operator: '='
                    }
                  ]}
                ></BaseSelect>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>{this.dataLang[row.vehicle_fines_statuses__name]}</span>
            )
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.indicator {
  display: flex;
  padding: 5px 0;
  border-radius: 5px;
  background-color: #23b14d;
  color: white;
  width: 80px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  &--warn {
    background-color: #ff3a20;
  }
}
</style>
