<template>
  <label class="base-checkbox">
    <input
      type="checkbox"
      class="base-checkbox__input"
      :checked="shouldBeChecked"
      :value="value"
      @change="updateInput"
      :name="name"
      :disabled="disabled"
    />
    <div
      class="base-checkbox__fill-square base-checkbox__fill-square--active-input"
    ></div>
    <span class="base-checkbox__sign" :class="signClass">
      <slot>Лучший чекбокс</slot>
    </span>
  </label>
</template>
<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: {
      type: Number
    },
    modelValue: {
      default: false
    },
    inputName: {
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    trueValue: {
      default: 1
    },
    falseValue: {
      default: 0
    },
    name: {
      default: ''
    },
    signClass: {
      default: ''
    },
    additionalParams: {
      default: () => {}
    }
  },
  computed: {
    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }

      return this.modelValue == this.trueValue
    }
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue]

        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }

        this.$emit('oncheckeds', {
          name: this.inputName,
          value: Number(newValue)
        })
      } else {
        this.$emit('oncheckeds', {
          name: this.inputName,
          value: isChecked ? this.trueValue : this.falseValue,
          ...this.additionalParams
        })
      }
    }
  }
}
</script>
