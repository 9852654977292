<template>
        <label class="base-radio">
            <input type="radio" class="base-radio__input" :checked="shouldBeChecked" @change="updateInput" :name="name">
            <div class="base-radio__fill-square base-radio__fill-square--active-input"></div>
            <span class="base-radio__sign">
                <slot></slot>
            </span>
        </label>
</template>


<script>
  export default {
    model: {
      prop: 'modelValue',
      event: 'change'
    },
    props: {
      value: {
        type: String,
      },
      modelValue: {
        default: ""
      },
      inputName:{
        default: ''
      },
      name: {
        default: ''
      },
      additionalInfo: {
        type: Object
      }
    },

    computed: {
      shouldBeChecked() {
        return this.modelValue == this.value    }
    },

    methods: {
      updateInput() {
        this.$emit('change', {name: this.inputName, value: this.value, ...this.additionalInfo})
      }
    }
  }
</script>